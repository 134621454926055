<template>
    <dropdown-click v-if="receiveNotifications.isActive" opens-towards="bottom">
        <template #button>
            <a
                v-cy="'notification-popover-trigger'"
                class="flex items-center h-5 text-base hover:text-hover"
            >
                <bell-icon
                    v-cy="'notification-indicator'"
                    indicator-class="border-background-light"
                    :show-indicator="hasUnreadNotifications"
                />
            </a>
        </template>
        <template #content="{close}">
            <div v-cy="'notification-popover-content'" class="p-2 w-96">
                <div class="flex gap-1 items-center text-xl">
                    <bell-icon class="w-5 h-5" />
                    <h4 class="text-lg font-bold" v-text="translate('app.notifications.title')" />
                    <close-button class="ml-auto cursor-pointer" @click="close" />
                </div>
                <div class="overflow-y-auto my-1 max-h-[400px]">
                    <div v-if="hasNotArchivedNotifications">
                        <toast-container
                            v-for="notification in notArchivedNotifications"
                            :key="notification.id"
                            v-cy="'popover-notification'"
                            v-element-visibility="getElementVisibilityHandler(notification)"
                            class="w-full"
                            :close-callback="() => markAsArchived(notification)"
                            :message="NotificationView.toToastMessage(notification)"
                            prevent-close-on-click
                        />
                    </div>
                    <div v-else class="relative">
                        <alien-illustration
                            class="absolute top-[47%] left-[19%] animate-[notification-alien_4s_ease-in-out_infinite] z-1"
                        />
                        <empty-notifications-illustration class="z-2" />
                        <p
                            class="absolute top-[39%] left-[47%] p-2 w-[37%] h-[23%] text-sm font-bold"
                            v-text="translate('app.notifications.inbox.all_read')"
                        />
                    </div>
                </div>
                <div class="flex justify-end items-center">
                    <router-link
                        v-cy="'popover-archive-link'"
                        class="flex gap-2 items-center hover:text-hover"
                        :to="{name: 'Archive'}"
                        @click="close"
                    >
                        {{ translate('app.notifications.inbox.archive_link') }}
                        <arrow-icon direction="right" />
                    </router-link>
                </div>
            </div>
        </template>
    </dropdown-click>
</template>

<script setup>
import BellIcon from '../../icons/BellIcon.vue';
import {storeToRefs} from 'pinia';
import {useNotificationStore} from '../../../../stores/notificationStore';
import DropdownClick from '../../core/DropdownClick.vue';
import CloseButton from '../../toast/CloseButton.vue';
import ArrowIcon from '../../icons/ArrowIcon.vue';
import EmptyNotificationsIllustration from '../../ilustrations/EmptyNotificationsIllustration.vue';
import AlienIllustration from '../../ilustrations/AlienIllustration.vue';
import ToastContainer from '../../toast/ToastContainer.vue';
import {translate} from '../../../mixins/translation';
import {vElementVisibility} from '@vueuse/components';
import debounce from 'lodash/debounce';
import NotificationView from '../../../../api/models/view/NotificationView';
import {useFeatureFlagStore} from '../../../../stores/featureFlagStore';

const {receiveNotifications} = storeToRefs(useFeatureFlagStore());

const {markAsArchived, markAsRead} = useNotificationStore();
const {hasUnreadNotifications, notArchivedNotifications, hasNotArchivedNotifications} = storeToRefs(
    useNotificationStore()
);

function getElementVisibilityHandler(notification) {
    return debounce(isVisible => {
        if (!isVisible) {
            return;
        }
        markAsRead(notification);
    }, 2000);
}
</script>

<style>
@keyframes notification-alien {
    from {
        transform: none;
    }
    33% {
        transform: translateY(-5%) translateX(10%);
    }
    66% {
        transform: translateY(0) translateX(20%);
    }
    to {
        transform: translateY(0) translateX(0);
    }
}
</style>