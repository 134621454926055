import UnauthenticatedError from '../../api/helpers/UnauthenticatedError';
import {useSecurityStore} from '../../stores/securityStore';
import {useInformationStore} from '../../stores/informationStore';

/**
 * Runs initial refresh token / information call logic
 * @return {NavigationGuardResult}
 */
export const refreshInformationData = async () => {
    const securityStore = useSecurityStore();
    const informationStore = useInformationStore();

    if (informationStore.isInitialized) {
        return;
    }

    try {
        await securityStore.refreshAccessToken();
    } catch (err) {
        if (!(err instanceof UnauthenticatedError)) {
            throw err;
        }
    }
};
