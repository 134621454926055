<template>
    <div class="h-full">
        <no-license-notice />
        <toast-portal />
        <responsive-header v-if="isHeaderVisible" />

        <div v-if="!isImpersonatingLoading" :style="containerStyle">
            <router-view />
        </div>

        <impersonate-info
            v-if="isImpersonating"
            :account-name="impersonating.accountName"
            :username="impersonating.name"
        />
        <app-footer />

        <loading-modal ref="loadingModal" />
    </div>
</template>
<script>
import whiteLabelHelper from './api/helpers/whiteLabelHelper.js';
import AppFooter from './frontstage/components/AppFooter.vue';
import ImpersonateInfo from './frontstage/components/ImpersonateInfo.vue';
import NoLicenseNotice from './frontstage/components/NoLicenseNotice.vue';
import LoadingModal from './shared/components/LoadingModal.vue';
import ResponsiveHeader from './shared/components/header/ResponsiveHeader.vue';
import breakpoint from './shared/mixins/breakpoint.js';
import userHelper from './shared/mixins/userHelper.js';
import {useEventBus} from '@vueuse/core';
import {useToast} from './shared/components/toast/useToast.js';
import {mapActions, mapState, mapWritableState} from 'pinia';
import {useSecurityStore} from './stores/securityStore';
import {useOidcStore} from './stores/oidcStore';
import {useImpersonatingStore} from './stores/impersonatingStore';
import {useUserStore} from './stores/userStore';
import {useAccountStore} from './stores/accountStore';
import {useGlobalStore} from './stores/globalStore';
import {useUserFeatureFlagStore} from './stores/userFeatureFlagStore';
import ToastPortal from './shared/components/toast/ToastPortal.vue';
import {useScriptLoader} from './shared/composables/useScriptLoader';

export default {
    components: {
        ToastPortal,
        NoLicenseNotice,
        LoadingModal,
        ImpersonateInfo,
        AppFooter,
        ResponsiveHeader,
    },
    mixins: [userHelper, breakpoint, whiteLabelHelper],
    props: {
        flashes: {
            type: String,
            default: '{}',
        },
    },
    setup() {
        useGlobalStore().init();
        useScriptLoader();
    },
    data: () => ({
        isReady: false,
    }),
    computed: {
        ...mapState(useUserStore, ['isAdmin', 'user', 'isAuthenticated']),
        ...mapState(useSecurityStore, ['msal']),
        ...mapWritableState(useSecurityStore, ['token', 'refreshToken', 'authType']),
        ...mapState(useOidcStore, ['hasOidcToken']),
        ...mapState(useImpersonatingStore, [
            'impersonating',
            'isImpersonating',
            'isImpersonatingLoading',
        ]),
        ...mapState(useAccountStore, ['account', 'accountHasActiveLicense']),
        ...mapState(useUserFeatureFlagStore, [{loading: 'userFeatureFlagsLoading'}]),
        showNotificationBanner() {
            return !this.accountHasActiveLicense;
        },
        containerStyle() {
            let heightAdjustment = this.isHeaderVisible ? 5 : 0;

            if (this.showNotificationBanner) {
                heightAdjustment += 2.5;
            }

            // old designs of landing pages need an additional bottom padding for new footer
            // TODO: remove additional padding, when landing pages are updated
            let footerPadding = '';

            //adjust height for public pages with footer
            if (this.$route.meta.public) {
                heightAdjustment += 1;
                footerPadding = '; padding-bottom: 80px';
            }

            return `${
                this.$route.meta.public ? 'min-height' : 'height'
            }: calc(100% - ${heightAdjustment}rem)${footerPadding}`;
        },
        isHeaderVisible() {
            return !this.$route.meta.hideNavbar && this.isReady;
        },
    },
    watch: {
        userFeatureFlagsLoading(value) {
            if (!value) {
                this.$refs.loadingModal.visible = false;
            }
        },
        isImpersonatingLoading(loading) {
            this.$refs.loadingModal.visible = loading;
        },
    },
    beforeCreate() {
        whiteLabelHelper.methods.setGlobalColors();
        whiteLabelHelper.methods.setDefaultColors();
    },
    async created() {
        this.initializeResourceTypes();

        await this.$router.isReady();
        this.isReady = true;

        let resourceType = 'seat';
        if (this.$route.name.toLowerCase().includes('zone')) {
            resourceType = 'zone';
        }

        if (this.$route.name.toLowerCase().includes('meetingroom')) {
            resourceType = 'meetingRoom';
        }

        this.$store.dispatch('resources/selectResource', resourceType);

        const query = Object.assign({}, this.$route.query);
        delete query.token;
        delete query.refreshToken;
        delete query.authType;
    },
    mounted() {
        this.$watch(
            () => this.isAuthenticated,
            isAuthenticated => {
                if (isAuthenticated) {
                    this.$refs.loadingModal.visible = false;
                }
            },
            {immediate: true}
        );

        this.$watch(
            () => this.account,
            () => {
                whiteLabelHelper.methods.whiteLabelCheck(this.account);
                this.initializeResourceTypes();
            },
            {immediate: true}
        );

        // Workaround
        setTimeout(() => {
            const flashes = JSON.parse(this.flashes);

            if (typeof flashes === 'object' && !!Object.keys(flashes).length) {
                const toast = useToast();
                for (const message of flashes.error || []) {
                    toast.error(this.__(message));
                }

                for (const message of flashes.success || []) {
                    toast.success(this.__(message));
                }

                for (const message of flashes.warning || []) {
                    toast.warning(this.__(message));
                }

                for (const message of flashes.info || []) {
                    toast.info(this.__(message));
                }
            }
        }, 0);
    },
    methods: {
        ...mapActions(useSecurityStore, ['clearToken', 'refreshAccessToken']),
        ...mapActions(useImpersonatingStore, ['impersonate']),
        initializeResourceTypes() {
            useEventBus('resourceTypesInitialized');
            this.$store
                .dispatch('resources/initializeResourceTypes', {
                    accountInformation: this.account,
                    currentRouteName: '',
                })
                .then(() => {
                    useEventBus('resourceTypesInitialized').emit();
                });
        },
    },
};
</script>
